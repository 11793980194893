import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import cx from "classnames";
import {
  container,
  slogan,
  about,
  title,
  links,
  link,
  mounted
} from "../styles/Home.module.css";

import SEO from "../components/seo";

const Home = () => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    require("splitting")({
      target: "[data-splitting]",
      by: "chars"
    });
    setMounted(true);
  });

  return (
    <div className={container}>
      <SEO
        title="Home"
        keywords={[
          "Vince Picone",
          "Software Developer",
          "Web Developer",
          "IBM",
          "Austin"
        ]}
      />
      <div
        data-splitting
        className={cx(slogan, {
          [mounted]: isMounted
        })}
      >
        Who are you without the doing?
      </div>
      <main className={about}>
        <h1 className={title}>
          <span style={{ WebkitTextFillColor: "#171717" }}>I'm</span> Vince
        </h1>
        <p>
          My passion is crafting performant, beautiful, accessible, experiences
          and empowering other developers and designers to do the same.
        </p>
        <p>
          I work on Carbon, IBM's open source design system, where I develop
          tools that enable content authors from all disciplines to speak IBM's
          design language.
        </p>
        <ul className={links}>
          <li>
            <a className={link} href="https://github.com">
              GitHub
            </a>
          </li>
          <li>
            <a className={link} href="https://github.com">
              Twitter
            </a>
          </li>
          <li>
            <Link className={link} to="/work">
              Work
            </Link>
          </li>
          <li>
            <Link className={link} to="/talks">
              Talks
            </Link>
          </li>
        </ul>
      </main>
    </div>
  );
};

export default Home;
